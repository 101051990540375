<template>
  <v-row>
    <v-col md="12">
      <v-card>
        <v-card-title>
          <v-icon
            @click="pushRouteToView('benefits')"
            class="mr-4"
            style="cursor: pointer"
            title="voltar"
          >
            mdi-arrow-left
          </v-icon>
          <v-icon class="mr-2">mdi-handshake</v-icon>
          <span v-if="id">Editar </span><span v-else>Adicionar </span> &nbsp;Benefício
         <!-- {{ routeTitle }} Benefício -->
        </v-card-title>
        <v-card-text>
          <v-form
            v-model="benefitFormValidation"
            ref="benefitForm"
            @submit.prevent="submitBenefit(benefit.id)"
          >
            <v-row>
              <v-col lg="12" md="12" sm="12">
                <v-text-field
                  :rules="textRules"
                  outlined
                  v-model="benefit.desc"
                  label="Descrição"
                  v-uppercase
                />
              </v-col>
            </v-row>
            <v-row v-show="!benefit.id">
              <v-col class="text-left" sm="12">
                <h3>Categorias</h3>
                <hr class="mb-0"/>
              </v-col>

              <v-col lg="12" md="12" sm="12">
                <v-checkbox
                  v-model="benefit.allEmployees"
                  label="Todos os Funcionários"
                  @click="triggerCheckbox"
                />
              </v-col>

              <v-col v-if="!benefit.allEmployees" lg="12" md="12" sm="12">
                <v-autocomplete
                  chips
                  deletable-chips
                  multiple
                  outlined
                  solo
                  auto-select-first
                  v-model="benefit.categories"
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  label="Categorias"
                >
                  <!--                  <template v-slot:prepend-item>-->
                  <!--                    <v-list-item ripple @click="selectAllCategories">-->
                  <!--                      <v-list-item-action>-->
                  <!--                        <v-icon-->
                  <!--                          :color="-->
                  <!--                            benefit.categories.length > 0-->
                  <!--                              ? 'indigo darken-4'-->
                  <!--                              : ''-->
                  <!--                          "-->
                  <!--                        >-->
                  <!--                          {{ icon }}-->
                  <!--                        </v-icon>-->
                  <!--                      </v-list-item-action>-->
                  <!--                      <v-list-item-content>-->
                  <!--                        <v-list-item-title>-->
                  <!--                          Selecionar Todos-->
                  <!--                        </v-list-item-title>-->
                  <!--                      </v-list-item-content>-->
                  <!--                    </v-list-item>-->
                  <!--                    <v-divider class="mt-2"></v-divider>-->
                  <!--                  </template>-->
                  <!--                  <v-divider class="mt-2"></v-divider>-->
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" class="text-right">
                <v-btn type="submit" color="success">
                  <v-icon left> mdi-content-save</v-icon>
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {CATEGORIES_GET} from "@/store/actions/categories.type";
import {
  //BENEFIT_GET,
  BENEFIT_CREATE, BENEFIT_GET,
  BENEFIT_UPDATE,
} from "@/store/actions/benefit.type";
import {
  BENEFIT_RESET,
} from "@/store/mutations/benefit.type";
import {CHANGE_PROGRESS} from "@/store/mutations/mutations.type";
// import {OnlyIDArr} from "@/utils/arrays";

export default {
  name: "categoriesEdit",
  props: {
    id: {
      default: 0,
      type: Number,
      required: false,
    },
  },

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(BENEFIT_RESET);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    if (to.params.id !== undefined) {
      await store.dispatch(BENEFIT_GET, to.params.id);
    }else{
      await store.dispatch(CATEGORIES_GET);
    }
    return next();
  },

  data: () => ({
    method: () => {
    },
    routeTitle: "Default",
    benefitFormValidation: false,
    // categoriesSelected: [],
    textRules: [(v) => !!v || "Este campo é obrigatório"],
  }),

  mounted() {
    // this.checkRoute();
  },

  methods: {
    // selectAllCategories() {
    //   this.$nextTick(() => {
    //     if (this.allEmployee) {
    //       this.categoriesSelected = [];
    //     } else {
    //       this.categoriesSelected = OnlyIDArr(this.categories);
    //     }
    //   });
    // },
    pushRouteToView(route) {
      this.$router.push({name: route});
    },
    submitBenefit(id) {

      if (this.$refs.benefitForm.validate()) {
        let action = id ? BENEFIT_UPDATE : BENEFIT_CREATE;

        store.commit(CHANGE_PROGRESS, true);
        
        store
          .dispatch(action)
          .then((res) => {
            store.commit(CHANGE_PROGRESS, false);

            this.snackbar.snackbar = true;
            this.snackbar.text = "Salvo com sucesso";
            this.snackbar.color = "success";

            if (res.data.id) {
              this.$router.push({
                name: "benefit",
                params: {id: res.data.id},
              });
            } else {
              this.$router.push({
                name: "benefit",
                params: {id: id}
              })
            }
          })
          .catch((response) => {
            store.commit(CHANGE_PROGRESS, false);
            this.snackbar.snackbar = true;
            this.snackbar.text = `Erro ao Salvar Benefício`;
            this.snackbar.color = "error";
            if (typeof response.data.message !== "undefined") {
              this.snackbar.text = response.data.message;
            }
          });
      } else {
        this.snackbar.snackbar = true;
        this.snackbar.color = "error";
        this.snackbar.text = "Preencha o formulário corretamente";
      }
    },

    // setBenefit() {
    //   store.commit(SET_BENEFIT_CATEGORIES, this.categoriesSelected);
    //   store.commit(CHANGE_PROGRESS, false);
    //   this.method();
    // },
    triggerCheckbox() {
      if (this.benefit.allEmployees) {
        this.benefit.categories = [];
      }
    },
  },
  computed: {
    ...mapGetters(["benefit", "categories", "checkUsers", "snackbar"]),
  }
}
;
</script>
